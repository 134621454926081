<template>
  <div class="pending-delivery">
    <SlListView
      @gotoPage="gotoPage"
      :total="page.total"
      :pageIndex="page.pageIndex"
      :pageSize="page.pageSize"
    >
      <div solt="search">
        <SlSearchForm
          v-model="query"
          :items="searchItems"
          :loading="tableLoading"
          @reset="gotoPage(page.pageSize)"
          @search="gotoPage(page.pageSize)"
        ></SlSearchForm>

        <SlTableToolbar>
          <SlButton type="primary" boxShadow="primary" @click="onOpenDialog('create')">新增</SlButton>
        </SlTableToolbar>

        <SlTableInfo :tableData="tableData" :columns="columns" headerAlign="left">
          <template #index="{index}">{{index + 1 + (page.pageIndex - 1) * page.pageSize}}</template>
          <div slot="operation" slot-scope="{row}">
            <el-button class="operation-btn" type="text" @click="onOpenDialog('read', row)">查看</el-button>
          </div>
        </SlTableInfo>
      </div>
    </SlListView>
    <!-- 收货/查看 Dialog -->
    <DeliveredDialog
      :show.sync="visible"
      :row="row"
      :operationType="operationType"
      @submited="gotoPage"
    />
  </div>
</template>

<script>
import OemInventory from '@api/oemInventory'
import DeliveredDialog from './stockDelivered/DeliveredDialog.vue'

export default {
  name: 'StockDelivered',
  components: {
    DeliveredDialog
  },
  data () {
    return {
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      query: {},
      searchItems: [
        {
          type: 'input',
          label: '入库单号',
          name: 'orderNumber'
        },
        {
          type: 'date',
          label: '操作时间',
          name: 'createAts',
          data: {
            datetype: 'daterange',
            isBlock: true
          }
        }
      ],
      tableLoading: false,
      tableData: [],
      columns: [
        {
          name: 'index',
          label: '序号',
          width: '100'
        },
        {
          name: 'orderNumber',
          label: '入库单号'
        }, {
          name: 'sourceSupplierName',
          label: '来源供应商'
        }, {
          name: 'createTime',
          label: '创建时间'
        }, {
          name: 'operation',
          label: '操作'
        }
      ],
      // dialog
      visible: false,
      operationType: 'create', // 可选值：'create', 'read'
      row: {}
    }
  },
  methods: {
    gotoPage (pageSize = 10, pageIndex = 1) {
      const params = this.generateParams(pageSize, pageIndex)
      this.tableLoading = true
      OemInventory.stockOrderList(params).then(res => {
        if (res.success) {
          const data = res.data || {}
          this.tableData = data.list
          this.page.total = data.total
          this.page.pageIndex = pageIndex
          this.page.pageSize = pageSize
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    generateParams (pageSize, pageIndex) {
      let { createAts = [], ...orther } = this.query
      return {
        ...orther,
        pageIndex,
        pageSize,
        operateStartTime: createAts && createAts[0] ? createAts[0] : '',
        operateEndTime: createAts && createAts[1] ? createAts[1] : ''
      }
    },
    onOpenDialog (operationType, row) {
      if (row) {
        this.row = row
      }
      this.operationType = operationType
      this.visible = true
    }
  }
}
</script>

<style scoped lang="scss">
.operation-btn {
  margin-left: 0 !important;
  margin-right: 0.8rem;
}
</style>
