<template>
  <SlDialog
    :title="title"
    :visible.sync="show"
    :width="dialogWidth"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :lock-scroll="false"
    :append-to-body="true"
    :before-close="close"
    @cancel="close"
    @closed="closed"
  >
    <el-form ref="form" :model="tableForm" :disabled="operationType === 'read'">
      <div class="mb-2rem">
        <el-form-item
          label-width="100px"
          label="来源供应商"
          prop="sourceSupplierId"
          :rules="rules.sourceSupplierId"
        >
          <el-select
            v-model="tableForm.sourceSupplierId"
            placeholder="请选择"
            @change="onSelectChange"
          >
            <el-option
              v-for="item in supplierList"
              :key="item.sourceSupplierId"
              :label="item.sourceSupplierName"
              :value="item.sourceSupplierId"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="mb-2rem" v-show="operationType === 'create'">
        <SlButton type="primary" boxShadow="primary" @click="onOpenSkuDialog">添加底版SKU</SlButton>
      </div>

      <div class="mb-2rem" v-show="operationType === 'read'">
        <el-row :gutter="10">
          <el-col :span="8">
            <span class="color-text--primary mr-8px">入库单号:</span>
            <span class="color-text--minor">{{ row.orderNumber }}</span>
          </el-col>
          <el-col :span="8">
            <span class="color-text--primary mr-8px">创建时间:</span>
            <span class="color-text--minor">{{ row.createTime }}</span>
          </el-col>
        </el-row>
      </div>

      <SlTableInfo
        :isEmbedTable="true"
        :tableData="tableForm.skuList"
        :columns="columns"
        headerAlign="left"
        max-height="360"
      >
        <template #receiveQuantity="{ row, index }">
          <template v-if="operationType === 'create'">
            <el-form-item
              label-width="0px"
              :prop="'skuList.' + index + '.receiveQuantity'"
              :rules="rules.receiveQuantity"
            >
              <el-input
                v-model="row.receiveQuantity"
                v-slFormatNumber="numberFormat"
                placeholder=">0"
              ></el-input>
            </el-form-item>
          </template>
          <template v-else>
            <span class="color-primary">{{row.receiveQuantity}}</span>
          </template>
        </template>
      </SlTableInfo>

      <el-form-item label="备注：" label-width="60px" prop="remark" style="margin-top: 16px;">
        <el-input
          maxlength="50"
          show-word-limit
          type="textarea"
          v-model="tableForm.remark"
          rows="3"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #bottom>
      <template v-if="operationType === 'create'">
        <el-button @click="close">{{$t('button.cancelText')}}</el-button>
        <el-button
          type="primary"
          :loading="handleLoading"
          @click="handleSubmit"
        >{{$t('button.enterText')}}</el-button>
      </template>
      <template v-else>
        <el-button @click="close">关闭</el-button>
      </template>
    </template>
    <!-- Dialog -->
    <SkuByBaseBoardSuppler
      :show.sync="innerVisible"
      :sourceSupplierId="tableForm.sourceSupplierId"
      @submited="getBaseBoardSkus"
    />
  </SlDialog>
</template>
<script>
import OemInventory from '@api/oemInventory'
import SkuByBaseBoardSuppler from './SkuByBaseBoardSuppler.vue'
import { uniqBy } from 'lodash'

export default {
  name: 'DeliveredDialog',
  components: { SkuByBaseBoardSuppler },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    },
    operationType: {
      type: String,
      default: 'create',
      validator (value) {
        return ['create', 'read'].includes(value)
      }
    }
  },
  computed: {
    title () {
      return (this.operationType === 'read' ? '查看' : '新增') + '底版入库'
    }
  },
  data () {
    return {
      handleLoading: false,
      dialogWidth: '800',
      numberFormat: { type: 'integer', max: 999999, compareLength: false, includeZero: false },
      columns: [
        {
          name: 'skuCode',
          label: '底版SKU'
        }, {
          name: 'skuImage',
          label: '图片',
          isImage: true,
          data: {
            imgSize: '6rem'
          }
        }, {
          name: 'productName',
          label: '商品名称'
        }, {
          name: 'receiveQuantity',
          label: '入库数量'
        }
      ],
      tableForm: {
        sourceSupplierId: '',
        sourceSupplierName: '', // extra
        skuList: [],
        remark: ''
      },
      rules: {
        receiveQuantity: [
          {
            required: true,
            message: '请输入入库数量',
            triggger: 'blur'
          }
        ],
        sourceSupplierId: [
          {
            required: true,
            message: '请选择来源供应商'
          }
        ]
      },
      supplierList: [],
      // inner dialog
      innerVisible: false
    }
  },
  mounted () {
    this.getSupplierList()
  },
  methods: {
    getSupplierList () {
      OemInventory.supplier().then(res => {
        if (res.success) {
          this.supplierList = res.data || []
        }
      })
    },
    onOpenSkuDialog () {
      if (this.tableForm.sourceSupplierId) {
        this.innerVisible = true
      } else {
        this.$refs.form.validateField('sourceSupplierId')
      }
    },
    getBaseBoardSkus (skuList) {
      this.tableForm.skuList = this.combineArr(this.tableForm.skuList, skuList)
    },
    // 数组合并去重
    combineArr (arr1, arr2) {
      return uniqBy(arr1.concat(arr2), 'skuCode')
    },
    // 切来源供应商，清除sku列表
    onSelectChange () {
      this.tableForm.skuList = []
    },
    handleSubmit () {
      if (!this.tableForm.skuList.length) {
        this.$message.warning('请添加底版SKU！')
        return
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.handleLoading = true
          // 若有性能问题，后期优化
          this.tableForm.sourceSupplierName = this.supplierList.find(item => item.sourceSupplierId === this.tableForm.sourceSupplierId).sourceSupplierName
          const submitData = { ...this.tableForm }
          // 特殊的数据结构
          submitData.skuList = submitData.skuList.map(sku => ({
            receiveQuantity: sku.receiveQuantity,
            sku
          }))
          OemInventory.stockOrderAdd(submitData).then(res => {
            if (res.success) {
              this.$message.success('新增底版入库提交成功！')
              this.$emit('submited')
              this.close()
            }
          }).finally(() => {
            this.handleLoading = false
          })
        }
      })
    },
    close () {
      this.$emit('update:show', false)
    },
    closed () {
      this.$refs.form.resetFields()
      this.tableForm.skuList = []
    }
  },
  watch: {
    show (bool) {
      if (bool) {
        if (this.operationType === 'read') {
          OemInventory.stockOrderDetail(this.row.id).then(res => {
            if (res.success) {
              // 转换数据结构-skuList内部sku打平
              const data = res.data || {}
              data.skuList = data.skuList.map(item => ({
                ...item,
                ...item.sku
              }))
              this.tableForm = data
            }
          })
        }
      }
    }
  }
}
</script>
<style lang="scss">
@import '@assets/scss/_var.scss';
.line-height-20 {
  line-height: 2rem;
}

.logistics-main {
  border: 1px solid #edf0f2;
  .logistics-main--header {
    padding-left: 1rem;
    line-height: 4rem;
    background-color: $color-table-header-bg;
  }

  .logistics-main--content {
    padding: 1rem;
  }
}

.prop-label {
  color: $color-text-primary;
}

.prop-value {
  color: $color-text-minor;
}
.color-primary {
  color: $color-primary;
}
</style>
